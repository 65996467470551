export const links = [
	{
		"name": "gh-001",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-001-flera-cykelmarken.html"
	},
	{
		"name": "gh-002",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-002-flera-cykelmarken.html"
	},
	{
		"name": "gh-003",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-003-flera-cykelmarken.html"
	},
	{
		"name": "gh-005",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-005-flera-cykelmarken.html"
	},
	{
		"name": "gh-006",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-006-flera-cykelmarken.html"
	},
	{
		"name": "gh-009",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-009-flera-cykelmarken.html"
	},
  {
    "name": "gh-010",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-010-flera-cykelmarken.html"
  },
	{
		"name": "gh-011",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-011-flera-cykelmarken.html"
	},
	{
		"name": "gh-012",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-012-flera-cykelmarken.html"
	},
	{
		"name": "gh-013",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-013-flera-cykelmarken.html"
	},
	{
		"name": "gh-014",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-014-flera-cykelmarken.html"
	},
	{
		"name": "gh-015",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-015-flera-cykelmarken.html"
	},
	{
		"name": "gh-017",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-017-flera-cykelmarken.html"
	},
	{
		"name": "gh-018",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-018-flera-cykelmarken.html"
	},
	{
		"name": "gh-020",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-020-flera-cykelmarken.html"
	},
  {
    "name": "gh-021",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-021-bianchicolnagogiantkona-diverse.html"
  },
	{
		"name": "gh-022",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-022-flera-cykelmarken.html"
	},
	{
		"name": "gh-023",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-023-flera-cykelmarken.html"
	},
	{
		"name": "gh-026",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-026-flera-cykelmarken.html"
	},
	{
		"name": "gh-029",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-029-flera-cykelmarken.html"
	},
	{
		"name": "gh-033",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-033-flera-cykelmarken.html"
	},
	{
		"name": "gh-036",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-036-flera-cykelmarken.html"
	},
  {
    "name": "gh-038",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-038-flera-cykelmarken.html"
  },
  {
    "name": "gh-039",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-039-flera-cykelmarken.html"
  },
  {
    "name": "gh-041",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-041-flera-cykelmarken.html"
  },
  {
    "name": "gh-043",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-043-flera-cykelmarken.html"
  },
  {
    "name": "gh-045",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-045-flera-cykelmarken.html"
  },
	{
		"name": "gh-049",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-049-flera-cykelmarken.html"
	},
  {
    "name": "gh-056",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-056-flera-cykelmarken.html"
  },
  {
    "name": "gh-057",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-057-flera-cykelmarken.html"
  },
  {
    "name": "gh-058",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-058-flera-cykelmarken.html"
  },
	{
		"name": "gh-061",
		"link": ""
	},
  {
		"name": "gh-062",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-062-flera-cykelmarken.html"
	},
  {
    "name": "gh-063",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-063-flera-cykelmarken.html"
  },
	{
		"name": "gh-068",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-068-trek-diverse.html"
	},
	{
		"name": "gh-069",
		"link": ""
	},
  {
    "name": "gh-072",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-072-flera-cykelmarken.html"
  },
	{
		"name": "gh-078",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-078-flera-cykelmarken.html"
	},
  {
    "name": "gh-080",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-080-flera-cykelmarken.html"
  },
	{
		"name": "gh-081",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-081-flera-cykelmarken.html"
	},
  {
    "name": "gh-082",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-082-flera-cykelmarken.html"
  },
	{
		"name": "gh-083",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-083-specialized-diverse.html"
	},
	{
		"name": "gh-084",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-084-specialized.html"
	},
  {
    "name": "gh-085",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-085-cube.html"
  },
  {
    "name": "gh-086",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-086-specialized.html"
  },
	{
		"name": "gh-089",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-089-specialized.html"
	},
  {
    "name": "gh-090",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-090-felt.html"
  },
  {
    "name": "gh-092",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-092-bianchi.html"
  },
  {
    "name": "gh-093",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-093-flera-cykelmarken.html"
  },
	{
		"name": "gh-094",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-094-specialized.html"
	},
	{
		"name": "gh-095",
		"link": ""
	},
	{
		"name": "gh-096",
		"link": ""
	},
	{
		"name": "gh-097",
		"link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-097-corratec-diverse.html"
	},
  {
    "name": "gh-098",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-098-ktm.html"
  },
  {
    "name": "gh-099",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-099-ktm-direct-mount-axle.html"
  },
  {
    "name": "gh-100",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-100-flera-cykelmarken.html"
  },
  {
    "name": "gh-101",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-101-flera-cykelmarken.html"
  },
  {
    "name": "gh-103",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-103-flera-cykelmarken.html"
  },
  {
    "name": "gh-104",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-104-flera-cykelmarken.html"
  },
  {
    "name": "gh-105",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-105-flera-cykelmarken.html"
  },
  {
    "name": "gh-106",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-106-flera-cykelmarken.html"
  },
  {
    "name": "gh-107",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-107-flera-cykelmarken.html"
  },
  {
    "name": "gh-108",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-108-cannondale.html"
  },
  {
    "name": "gh-109",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-109-cannondale.html"
  },
  {
    "name": "gh-110",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-110-giant.html"
  },
  {
    "name": "gh-111",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-111-trek.html"
  },
  {
    "name": "gh-112",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-112-trek.html"
  },
	{
		"name": "gh-113",
		"link": ""
	},
  {
    "name": "gh-114",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-114-flera-cykelmarken.html"
  },
  {
    "name": "gh-115",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-115-flera-cykelmarken.html"
  },
  {
    "name": "gh-116",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-116-flera-cykelmarken.html"
  },
  {
    "name": "gh-117",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-117-bianchicubefeltgary-fishertrek-diverse.html"
  },
	{
		"name": "gh-118",
		"link": ""
	},
  {
    "name": "gh-119",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-119-flera-cykelmarken.html"
  },
	{
		"name": "gh-120",
		"link": ""
	},
  {
    "name": "gh-121",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-121-flera-cykelmarken.html"
  },
  {
    "name": "gh-122",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-122-flera-cykelmarken.html"
  },
	{
		"name": "gh-123",
		"link": ""
	},
  {
    "name": "gh-124",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-124-flera-cykelmarken.html"
  },
  {
    "name": "gh-125",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-125-cannondale.html"
  },
  {
    "name": "gh-126",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-126-flera-cykelmarken.html"
  },
  {
    "name": "gh-127",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-127-flera-cykelmarken.html"
  },
  {
    "name": "gh-128",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-128-flera-cykelmarken.html"
  },
  {
    "name": "gh-129",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-129-flera-cykelmarken.html"
  },
  {
    "name": "gh-130",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-130-flera-cykelmarken.html"
  },
  {
    "name": "gh-131",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-131-flera-cykelmarken.html"
  },
  {
    "name": "gh-132",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-132-felt-diverse.html"
  },
  {
    "name": "gh-133",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-133-flera-cykelmarken.html"
  },
  {
    "name": "gh-134",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-134-giant.html"
  },
  {
    "name": "gh-135",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-135-giant.html"
  },
  {
    "name": "gh-136",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-136-giant.html"
  },
  {
    "name": "gh-137",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-137-trek.html"
  },
  {
    "name": "gh-138",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-138-trek.html"
  },
  {
    "name": "gh-139",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-139-trek.html"
  },
  {
    "name": "gh-140",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-140-specialized.html"
  },
  {
    "name": "gh-141",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-141-specialized.html"
  },
  {
    "name": "gh-142",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-142-specialized.html"
  },
  {
    "name": "gh-143",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-143-specialized.html"
  },
  {
    "name": "gh-144",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-144-gt.html"
  },
  {
    "name": "gh-145",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-145-flera-cykelmarken.html"
  },
  {
    "name": "gh-146",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-146-flera-cykelmarken.html"
  },
  {
    "name": "gh-147",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-147-cerveloeddy-merckx.html"
  },
  {
    "name": "gh-148",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-148-cannondale.html"
  },
   {
    "name": "gh-149",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-149-cannondale.html"
  },
  {
    "name": "gh-150",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-150-cannondale.html"
  },
  {
    "name": "gh-151",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-151-cannondale.html"
  },
  {
    "name": "gh-153",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-153-flera-cykelmarken.html"
  },
  {
    "name": "gh-154",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-154-flera-cykelmarken.html"
  },
  {
    "name": "gh-155",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-155-orbea.html"
  },
  {
    "name": "gh-156",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-156-scott.html"
  },
  {
    "name": "gh-157",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-157-scott.html"
  },
  {
    "name": "gh-158",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-158-cervelo.html"
  },
  {
    "name": "gh-159",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-159-specialized.html"
  },
  {
    "name": "gh-160",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-160-cannondale.html"
  },
  {
    "name": "gh-161",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-161-trek.html"
  },
  {
    "name": "gh-162",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-162-lapierrekoga.html"
  },
  {
    "name": "gh-163",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-163-scott.html"
  },
  {
    "name": "gh-164",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-164-cube.html"
  },
  {
    "name": "gh-165",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-165-cube.html"
  },
  {
    "name": "gh-166",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-166-specialized.html"
  },
  {
    "name": "gh-167",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-167-lapierreghost.html"
  },
  {
    "name": "gh-168",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-168-lapierre.html"
  },
  {
    "name": "gh-169",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-169-ghost.html"
  },
  {
    "name": "gh-170",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-170-ghost.html"
  },
  {
    "name": "gh-171",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-171-ghost.html"
  },
  {
    "name": "gh-172",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-172-ghost.html"
  },
  {
    "name": "gh-173",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-173-ghostherculeswinora-diverse.html"
  },
  {
    "name": "gh-174",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-174-ghostherculeswinora-diverse.html"
  },
  {
    "name": "gh-175",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-175-cube-hanger-axle-cap.html"
  },
  {
    "name": "gh-176",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-176-cube-hanger-axle-cap.html"
  },
  {
    "name": "gh-178",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-178-bergamont.html"
  },
  {
    "name": "gh-179",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-179-stevens.html"
  },
  {
    "name": "gh-180",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-180-stevenskuota-diverse.html"
  },
  {
    "name": "gh-181",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-181-scott-hanger-axle-cap.html"
  },
  {
    "name": "gh-182",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-182-scott-hanger-axle-cap.html"
  },
  {
    "name": "gh-183",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-183-giant-hanger-axle-cap.html"
  },
  {
    "name": "gh-185",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-185-giant.html"
  },
  {
    "name": "gh-186",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-186-giant-hanger-axle-cap.html"
  },
  {
    "name": "gh-187",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-187-direct-mount-axles-m12.html"
  },
  {
    "name": "gh-188",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-188-trek.html"
  },
  {
    "name": "gh-189",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-189-trek.html"
  },
  {
    "name": "gh-190",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-190-trek.html"
  },
  {
    "name": "gh-191",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-191-giant.html"
  },
  {
    "name": "gh-192",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-192-cannondale.html"
  },
	{
    "name": "gh-199",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-199-ktm-direct-mount-axle.html"
  },
  {
    "name": "gh-200",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-200-bianchifocusstevens-diverse.html"
  },
  {
    "name": "gh-201",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-201-cube.html"
  },
  {
    "name": "gh-202",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-202-bhsaracenbottechia-diverse.html"
  },
  {
    "name": "gh-203",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-203-bmcstevens.html"
  },
  {
    "name": "gh-204",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-204-focus.html"
  },
  {
    "name": "gh-205",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-205-fuji.html"
  },
  {
    "name": "gh-206",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-206-cannondale.html"
  },
  {
    "name": "gh-207",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-207-cervelo.html"
  },
  {
    "name": "gh-208",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-208-colnago.html"
  },
  {
    "name": "gh-209",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-209-wilier.html"
  },
  {
    "name": "gh-210",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-210-bulls.html"
  },
  {
    "name": "gh-211",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-211-bhbulls.html"
  },
  {
    "name": "gh-212",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-212-scott.html"
  },
  {
    "name": "gh-213",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-213-ghostmeridafelt-diverse.html"
  },
  {
    "name": "gh-214",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-214-focus.html"
  },
  {
    "name": "gh-215",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-215.html"
  },
  {
    "name": "gh-216",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-216-focus.html"
  },
  {
    "name": "gh-217",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-217-scott.html"
  },
  {
    "name": "gh-218",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-218-lapierre.html"
  },
  {
    "name": "gh-219",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-219-haibikebergamont.html"
  },
  {
    "name": "gh-220",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-220-focusraleighwilier-diverse.html"
  },
  {
    "name": "gh-221",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-221-lapierre.html"
  },
  {
    "name": "gh-222",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-222-lapierre.html"
  },
  {
    "name": "gh-223",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-223-flera-cykelmarken.html"
  },
  {
    "name": "gh-224",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-224-haibike.html"
  },
  {
    "name": "gh-225",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-225-trek.html"
  },
  {
    "name": "gh-226",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-226-bmc.html"
  },
  {
    "name": "gh-227",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-227-bergamont.html"
  },
  {
    "name": "gh-228",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-228-fuji.html"
  },
  {
    "name": "gh-229",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-229-focusdiamondback.html"
  },
  {
    "name": "gh-230",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-230-bmc.html"
  },
  {
    "name": "gh-231",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-231-pinarello.html"
  },
  {
    "name": "gh-232",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-232-felt.html"
  },
  {
    "name": "gh-233",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-233-giant.html"
  },
  {
    "name": "gh-234",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-234-wilier.html"
  },
  {
    "name": "gh-235",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-235-giant.html"
  },
  {
    "name": "gh-236",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-236-ghost.html"
  },
  {
    "name": "gh-237",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-237-bmc.html"
  },
  {
    "name": "gh-238",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-238-haibike-diverse.html"
  },
  {
    "name": "gh-239",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-239-wilier-diverse.html"
  },
  {
    "name": "gh-240",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-240-centurionmeridawilier-diverse.html"
  },
  {
    "name": "gh-241",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-241-lapierrefuji-diverse.html"
  },
  {
    "name": "gh-242",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-242-haibikegtbtwin.html"
  },
  {
    "name": "gh-243",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-243-bh.html"
  },
  {
    "name": "gh-244",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-244-bullsfocusraleigh-diverse.html"
  },
  {
    "name": "gh-245",
    "link": "hhttps://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-245-bullsfocusraleigh-diverse.html"
  },
  {
    "name": "gh-246",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-246-ktm.html"
  },
	{
    "name": "gh-247",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-247-ktm.html"
  },
  {
    "name": "gh-248",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-248-ktm.html"
  },
  {
    "name": "gh-249",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-249-bulls-direct-mount-axle.html"
  },
  {
    "name": "gh-250",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-250-bulls-direct-mount-axle.html"
  },
  {
    "name": "gh-251",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-251-bulls-direct-mount-axle.html"
  },
  {
    "name": "gh-252",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-252-bulls-direct-mount-axle.html"
  },
  {
    "name": "gh-253",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-253-bulls.html"
  },
  {
    "name": "gh-254",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-254-bulls.html"
  },
  {
    "name": "gh-255",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-255-bulls.html"
  },
  {
    "name": "gh-256",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-256-orbea.html"
  },
  {
    "name": "gh-257",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-257-cube.html"
  },
  {
    "name": "gh-258",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-258-stevenscorratec.html"
  },
  {
    "name": "gh-259",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-259-giant.html"
  },
  {
    "name": "gh-260",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-260-cannondalebulls.html"
  },
  {
    "name": "gh-261",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-261-giant.html"
  },
  {
    "name": "gh-262",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-262-bh.html"
  },
  {
    "name": "gh-263",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-263-bulls.html"
  },
  {
    "name": "gh-264",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-264-cube.html"
  },
  {
    "name": "gh-265",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-265-orbea.html"
  },
  {
    "name": "gh-266",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-266-focus-diverse.html"
  },
  {
    "name": "gh-267",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-267-focus.html"
  },
  {
    "name": "gh-268",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-268-decathlon.html"
  },
  {
    "name": "gh-269",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-269-stevens.html"
  },
  {
    "name": "gh-270",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-270-canyon.html"
  },
  {
    "name": "gh-271",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-271-bergamont.html"
  },
  {
    "name": "gh-272",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-272-bergamont.html"
  },
  {
    "name": "gh-273",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-273-bergamont.html"
  },
  {
    "name": "gh-274",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-274-orbea.html"
  },
  {
    "name": "gh-275",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-275-scott.html"
  },
	{
    "name": "gh-276",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-276-scott.html"
  },
  {
    "name": "gh-277",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-277-scott.html"
  },
  {
    "name": "gh-278",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-278-scott.html"
  },
  {
    "name": "gh-279",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-279-stevensfondriest.html"
  },
  {
    "name": "gh-280",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-280-stevens.html"
  },
  {
    "name": "gh-281",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-281-scott.html"
  },
  {
    "name": "gh-282",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-282-orbea.html"
  },
  {
    "name": "gh-283",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-283-gt.html"
  },
  {
    "name": "gh-284",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-284-gt.html"
  },
  {
    "name": "gh-285",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-285-cube.html"
  },
  {
    "name": "gh-286",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-286-cube.html"
  },
  {
    "name": "gh-287",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-287-cube.html"
  },
  {
    "name": "gh-288",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-288-cube.html"
  },
  {
    "name": "gh-289",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-289-focus.html"
  },
  {
    "name": "gh-290",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-290-focus.html"
  },
  {
    "name": "gh-291",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-291focus.html"
  },
  {
    "name": "gh-292",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-292-scott.html"
  },
  {
    "name": "gh-293",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-293-specialized.html"
  },
  {
    "name": "gh-294",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-294-specialized.html"
  },
  {
    "name": "gh-295",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-295-specialized.html"
  },
  {
    "name": "gh-296",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-296-specialized.html"
  },
  {
    "name": "gh-297",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-297-specialized.html"
  },
  {
    "name": "gh-298",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-298-specialized.html"
  },
  {
    "name": "gh-299",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-299-haibike.html"
  },
  {
    "name": "gh-300",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-300-haibike.html"
  },
  {
    "name": "gh-301",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-301-haibike-diverse.html"
  },
  {
    "name": "gh-302",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-302-specialized.html"
  },
  {
    "name": "gh-303",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-303-cannondale.html"
  },
  {
    "name": "gh-304",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-304-rose.html"
  },
  {
    "name": "gh-305",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-305-cannondale.html"
  },
  {
    "name": "gh-306",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-306-haibike.html"
  },
  {
    "name": "gh-307",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-307-stevens.html"
  },
  {
    "name": "gh-308",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-308-stevens.html"
  },
  {
    "name": "gh-309",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-309-focus.html"
  },
  {
    "name": "gh-310",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-310-giant.html"
  },
  {
    "name": "gh-311",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-311-cube.html"
  },
  {
    "name": "gh-312",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-312-stevens.html"
  },
  {
    "name": "gh-399",
    "link": "https://cykelnatur.se/sv/produkter/cykeldelar/vaxeloron/vaxelora-gh-399-cannondale.html"
  }
]