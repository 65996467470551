import React from 'react';

const APIView = () => {
  
  React.useEffect(() => {
    window.location.href = 'http://88.89.12.141:40105/';
}, [])
    return (
        <div>
            <h1>API View</h1>
        </div>
    );
};

export default APIView;
