import React from 'react';
import { useNavigate } from 'react-router-dom';

const ChooseHolesView = () => {
  const navigate = useNavigate();
  localStorage.setItem('resultImageUrl', '');
  
  const handleNumHolesChange = (value) => {
    navigate('/upload-view?numHoles=' + value);
  };

  return (<div className='choose-holes'>
    <h1 style={{ color: 'black' }}>Välj antal hål på växelörat</h1>
    <div>
      {[1, 2, 3, 4].map((num) => (
        <img
          key={num}
          src={`/images/categories/${num}_hole.png`}
          alt={`${num} hole(s)`}
          style={{ width: '166px', height: '200px', margin: '10px', cursor: 'pointer' }}
          onClick={() => handleNumHolesChange(num + "_hole")}
        />
      ))}
    </div>
  </div>)
}

export default ChooseHolesView;
