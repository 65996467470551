import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UploadView from './code/views/UploadView';
import ChooseHolesView from './code/views/ChooseHolesView.js';
import APIView from './code/views/APIView.js';
function App() {

  return (
    <div className='main'>
      <Router>
        <Routes>
            <Route path="/" element={<ChooseHolesView />} />
            <Route path="/upload-view" element={<UploadView />} />
            <Route path="/api" element={<APIView />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;