import React from 'react';
import './MathingHanger.css';
import { links } from '../data/Links';
import './UploadView.css';

const MatchingHanger = ({ resultHanger }) => {
  if (!resultHanger) {
    return null;
  }

  const link = links.find(link => link.name === resultHanger.name.replace('.jpg', ''));
  console.log(link);
  console.log(resultHanger);
  const handleBuyButtonClick = () => {
    if (link && link.link) {
      window.open(link.link, '_blank');
    }
  }

  return (
    <div>
      <img src={`${process.env.PUBLIC_URL}/images/hangers/${resultHanger.name}`} alt="Hanger" className="result-image" />
      <h2 className="hanger-name">Växelöra: {resultHanger.name.replace('.jpg', '')}</h2>
      <h2 className="hanger-similarity">Matchning: {Math.round(resultHanger.similarity * 100)}%</h2>
      {link && (
        <button className="button buy-button" onClick={handleBuyButtonClick}>
          Köp {link.name}
        </button>
      )}
    </div>
  );
}

export default MatchingHanger;